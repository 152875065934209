body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
  font-family: 'Montserrat', sans-serif;
  color: #637796;
}
.main-bg{
  background-image: -webkit-gradient(linear, left bottom, left top, from(#FFC3A0), to(#FFAFBD));
  background-image: -webkit-linear-gradient(bottom, #FFC3A0 0%, #FFAFBD 100%);
  background-image: linear-gradient(0deg, #FFC3A0 0%, #FFAFBD 100%);
  background-position: center center;
  background-repeat:  no-repeat;
  background-attachment: fixed;
  background-size:  cover;
  height: 100%;
  width: 100%;
}
.App {
  text-align: center;
}
header{
  padding: 40px;
  width: 100%;
  top: 0;
  z-index: 9;
}
header .nav a{
  font-weight: 300;
  letter-spacing: 1.0px;
  font-size: 13px;
}
header img{
  width: 240px;
  margin-top: 15px;
  margin-left: 15px;
}
header .contact{
  color: #637796;
  letter-spacing: 1.3px;
  margin-top: 15px;
  margin-right: 20px;
  text-decoration: none;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  justify-content: center;
  position: relative;
  z-index: 2;
}
.App-header .hero-p{
   border-left: 3px solid #fff;
   padding-left: 30px;
   margin-left: -15px;
   padding-top: 20px; 
   height: 100px;
   color: #fff;
}

.hero-text{
  margin: 15px 55px;
  position: fixed;
  top: 125px;
}
@media (max-width: 768px) {
  .App-header .hero-p{
    border-left: none;
    text-align: center;
  }
}
@media (max-width: 480px) {
  header{
    padding: 15px 5px;
  }
  header img{
    width: 200px;
  }
  .App-header{
    min-height: 100vh;
  }
  .App-header .hero-p{
    border-left: none;
    text-align: left;
    margin-left: 0px;
    padding-left: 0px;
  }
  .hero-text{
    margin: 15px 15px;
    top: 85px;
  }
  .everyone{
    display: none;
  }
  #root{
    margin-top: 95px;
  }
}

.App-link {
  color: #61dafb;
}
h2{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  letter-spacing: 1.7px;
}
h6{
  letter-spacing: 1.4px;
  color: rgba(53,53,53,0.75);
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 20px;
}

.border-inner{
    position: relative;
    top: -2px;
    width: 84px;
    height: 12px;
    margin-left: 2px;
    -webkit-transform: perspective(100px) rotateX(-60deg);
    transform: perspective(100px) rotateX(-60deg);
    background: inherit;
}

.border-input{
  background: none;
  border: 2px solid #637796;
  border-radius: 30px;
  height: 45px;
  min-width: 250px;
  padding-left: 20px;
  text-transform: uppercase;
  color: #637796;
  font-size: 14px;
}
.border-input:focus{
  background: none;
  border-color: white;
  box-shadow: none;
  color: #637796;
}
.player-icon{
  margin-top: 10px;
  width: 100px;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;

  justify-content: center;
}
.player-icon:hover{
  opacity: .7;
}
.player-icon:focus,
.player-icon::-moz-selection{
  opacity: 1.0;
}
.player-icon:focus,
.player-icon::selection{
  opacity: 1.0;
}
.purp-btn{
  display: inline-block;
  background: #637796;
  border-radius: 26px;
  color: #fff;
  font-weight: 300;
  font-size:14px;
  text-transform: uppercase;
  padding: 12px 35px;
  letter-spacing: 1.7px;
  text-decoration: none;
  cursor: pointer;
  border: none;
}
.cancel-save{
  display: flex;
  justify-content: center;
  min-height: 69px;
}
.cancel-btn{
  background: none;
  border: 2px solid #637796;
  padding: 12px;
  color: #637796;
  margin-top: 20px;
  width: 120px;
  display: inline-block;
}
.save-btn{
  margin-top: 20px;
  display: inline-block;
  justify-content: center;
}
.save-btn:focus{
  border: none;
  outline: none;
  opacity: .85;
}
.progress-circle{
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width:100px;
  position: absolute;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9;
  cursor: pointer;
}
.timestamp{
  margin: 15px;
}
.wave-holder{
  height: 96px;
  overflow: hidden;
  margin-top: 30px;
  padding-top: 30px;
}
#waveform{
  -webkit-transform: scale(1,2);
          transform: scale(1,2);
}

footer {
  min-height: 140px;
  margin-top: -140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
}

footer img{
  display: inline-block;
  margin: 5px;
  width: 24px;
}
footer p{
  letter-spacing: 1.0px;
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 20px;
}
